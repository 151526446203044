<template>
  <div class="row">
    <!-- <div class="col-md-4 mb-4">
      <b-button-group class="w-100" size="sm">
        <b-button
          v-for="key in dayNumList"
          :key="'num_' + key"
          :variant="isActive(key) ? 'success' : 'outline-success'"
          @click="chooseDateFilter(key)"
          >{{ key }} days</b-button
        >
      </b-button-group>
    </div>
    <div class="col-md-2 mb-4">
      <b-button variant="primary" size="sm" class="w-100" @click="exportUser"
        ><i class="far fa-arrow-alt-circle-down" style="margin-right: rem" />Export</b-button
      >
    </div>-->
    <div class="col-sm-12">
      <KTPortlet v-bind:class="'kt-portlet--height-fluid'" v-bind="{ bodyFit: true }">
        <template v-slot:body>
          <div class="kt-widget14" style="padding: 20px">
            <div class="kt-widget14__header" style="margin: 0; padding: 0">
              <h5 style="color: #db4437">Tickets without images</h5>
              <b-table
                striped
                :fields="headers"
                :items="items"
                :busy="isBusy"
                responsive
                bordered
                show-empty
                class="elevation-1 data-table text-center orderTable"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(printed_at)="item">{{ formatDate(new Date(item.item.printed_at)) }}</template>
              </b-table>
            </div>
          </div>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Ticket Printed</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.totalPrinted"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Tickets Without Images</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.totalNoImage"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Winning Ticket</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.totalWinning"></PieChart>
        </template>
      </KTPortlet>
    </div>
    <div class="col-md-6 col-sm-12">
      <KTPortlet
        v-bind="{
          class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
          headClass: 'kt-portlet__space-x text-danger',
          fluidHalfHeight: false,
          headNoBorder: true,
          bodyFit: true
        }"
      >
        <template v-slot:title>
          <h3 class="kt-portlet__head-title text-primary">Total Paid Ticket</h3>
        </template>
        <template v-slot:body>
          <PieChart variant="danger" :data="pieCharts.totalPaid"></PieChart>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>
<script>
import KTPortlet from '@/views/partials/content/Portlet.vue';
import PieChart from '@/views/partials/widgets/PieChart.vue';
import ApiService from '@/common/api.service.js';
import { mapGetters } from 'vuex';
import { exportRequest } from '@/utils/download.js';
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
export default {
  name: 'operator_statistics',
  components: {
    KTPortlet,
    PieChart
  },
  data() {
    return {
      totalUser: 10,
      from: null,
      to: null,
      pieCharts: {
        totalPrinted: [],
        totalNoImage: [],
        totalWinning: [],
        totalPaid: []
      },
      headers: [
        { label: 'Ticket ID', key: 'id' },
        { label: 'Game Name', key: 'game_name' },
        { label: 'Printed At', key: 'printed_at' },
        { label: 'Last Four Serial', key: 'last_four_serial' }
      ],
      items: [],
      isBusy: false,
      timeout: null
    };
  },
  created() {
    const { from, to } = this.getFromTo(new Date(), 0);
    this.from = from;
    this.to = to;
    this.getAllData();
    this.isBusy = true;
    ApiService.get('tickets/no-images', `?from=${from}&to=${to}`).then((resp) => {
      let data = resp.data.data;
      this.items = [...data.ticketList];
    });
    this.isBusy = false;
    this.timeout = setInterval(() => {
      this.isBusy = true;
      ApiService.get('tickets/no-images', `?from=${from}&to=${to}`).then((resp) => {
        let data = resp.data.data;
        this.items = [...data.ticketList];
      });
      this.isBusy = false;
    }, 15000);
  },
  destroyed() {
    clearInterval(this.timeout);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Statistics',
        route: 'operator-statistics'
      },
      { title: 'Operator Statistics' }
    ]);
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    user() {
      return this.$store.getters.currentUser.data;
    }
  },
  methods: {
    getFromTo(date, num) {
      let from, to;
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      to = new Date(date).toISOString();
      date.setDate(date.getDate() - num);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      from = new Date(date).toISOString();
      return { from, to };
    },
    async getAllData() {
      let res = await this.getData(this.from, this.to);
      const colorArr = [
        '#ffb822',
        '#5867dd',
        '#FF7B89',
        '#CBE54E',
        '#8950fc',
        '#0abb87',
        '#5d78ff',
        '#fd397a',
        '#e8ecfa',
        '#8950fc',
        '#0abb87'
      ];
      let data = res.data;
      let pieData = {};
      Object.keys(data).forEach((chart) => {
        let chartData = [];
        Object.keys(data[chart]).forEach((key, index) => {
          chartData.push({
            label: key,
            color: colorArr[index],
            value: data[chart][key]
          });
        });
        pieData[chart] = [...chartData];
      });
      this.pieCharts = { ...pieData };
    },
    getData(from, to) {
      return new Promise((resolve) => {
        if (!from) {
          from = new Date();
          from = new Date(from.setDate(from.getDate() - 1)).toISOString();
        }
        if (!to) to = new Date().toISOString();
        ApiService.get('statistics/operators', `?from=${from}&to=${to}`)
          .then((resp) => {
            resolve({ data: resp.data.data });
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
            resolve(false);
          });
      });
    },
    exportUser() {
      exportRequest(`/exports/customers?from=${this.from}&to=${this.to}`);
    }
  }
};
</script>
