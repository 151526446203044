<template>
  <div class="kt-widget14">
    <div v-if="data.length > 0" class="kt-widget14__content">
      <div class="kt-widget14__chart" style="width: 400px">
        <div class="kt-widget14__stat">
          {{ data.reduce((total, item) => total + parseFloat(item.value), 0).toLocaleString() }}
        </div>
        <Chart1 ref="chart" v-bind:options="chartOptions" height="250" width="250"></Chart1>
      </div>
      <div class="kt-widget14__legends">
        <div v-for="(item, index) in data" :key="'chart_' + title + '_' + index" class="kt-widget14__legend">
          <span class="kt-widget14__bullet" :style="'background-color: ' + item.color"></span>
          <span class="kt-widget14__stats">{{ item.label }}</span>
        </div>
      </div>
    </div>
    <div v-else class="text-muted text-center">No data</div>
  </div>
</template>
<style scoped>
.kt-widget14__legend {
  padding: 0.25rem 0 !important;
}
.kt-widget14__stat {
  font-size: 1.5rem !important;
  color: #646c9a !important;
}
</style>
<script>
import { mapGetters } from 'vuex';
import Chart1 from '@/views/partials/widgets/Chart1.vue';

export default {
  name: 'widget-14-2',
  components: {
    Chart1
  },
  data() {
    return {};
  },
  props: {
    title: [String, Number],
    desc: [String, Number],
    data: Array,
    label: Array
  },
  mounted() {},
  computed: {
    ...mapGetters(['layoutConfig']),
    chartOptions: function () {
      return {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: this.data.map((item) => item.value),
              backgroundColor: this.data.map((item) => item.color || '#fd397a')
            }
          ],
          labels: this.data.map((item) => item.label)
        },
        options: {
          cutoutPercentage: 75,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'top'
          },
          title: {
            display: false,
            text: this.title
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: 'nearest',
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: true,
            // backgroundColor: this.layoutConfig("colors.state.brand"),
            titleFontColor: '#ffffff',
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              label: function (tooltipItem, data) {
                const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                var label = data.labels[tooltipItem.index] || '';
                if (label) {
                  label += ': ';
                }
                label += parseFloat(data.datasets[0].data[tooltipItem.index]).toLocaleString();
                label += ` | ${parseFloat((data.datasets[0].data[tooltipItem.index] / total) * 100).toFixed(2)}%`;
                return label;
              }
            }
          }
        }
      };
    }
  }
};
</script>
