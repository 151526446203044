var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('KTPortlet',_vm._b({class:'kt-portlet--height-fluid',scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"kt-widget14",staticStyle:{"padding":"20px"}},[_c('div',{staticClass:"kt-widget14__header",staticStyle:{"margin":"0","padding":"0"}},[_c('h5',{staticStyle:{"color":"#db4437"}},[_vm._v("Tickets without images")]),_c('b-table',{staticClass:"elevation-1 data-table text-center orderTable",attrs:{"striped":"","fields":_vm.headers,"items":_vm.items,"busy":_vm.isBusy,"responsive":"","bordered":"","show-empty":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v(" Loading...")])],1)]},proxy:true},{key:"cell(printed_at)",fn:function(item){return [_vm._v(_vm._s(_vm.formatDate(new Date(item.item.printed_at))))]}}])})],1)])]},proxy:true}])},'KTPortlet',{ bodyFit: true },false))],1),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"kt-portlet__head-title text-primary"},[_vm._v("Total Ticket Printed")])]},proxy:true},{key:"body",fn:function(){return [_c('PieChart',{attrs:{"variant":"danger","data":_vm.pieCharts.totalPrinted}})]},proxy:true}])},'KTPortlet',{
        class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
        headClass: 'kt-portlet__space-x text-danger',
        fluidHalfHeight: false,
        headNoBorder: true,
        bodyFit: true
      },false))],1),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"kt-portlet__head-title text-primary"},[_vm._v("Total Tickets Without Images")])]},proxy:true},{key:"body",fn:function(){return [_c('PieChart',{attrs:{"variant":"danger","data":_vm.pieCharts.totalNoImage}})]},proxy:true}])},'KTPortlet',{
        class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
        headClass: 'kt-portlet__space-x text-danger',
        fluidHalfHeight: false,
        headNoBorder: true,
        bodyFit: true
      },false))],1),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"kt-portlet__head-title text-primary"},[_vm._v("Total Winning Ticket")])]},proxy:true},{key:"body",fn:function(){return [_c('PieChart',{attrs:{"variant":"danger","data":_vm.pieCharts.totalWinning}})]},proxy:true}])},'KTPortlet',{
        class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
        headClass: 'kt-portlet__space-x text-danger',
        fluidHalfHeight: false,
        headNoBorder: true,
        bodyFit: true
      },false))],1),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"kt-portlet__head-title text-primary"},[_vm._v("Total Paid Ticket")])]},proxy:true},{key:"body",fn:function(){return [_c('PieChart',{attrs:{"variant":"danger","data":_vm.pieCharts.totalPaid}})]},proxy:true}])},'KTPortlet',{
        class: 'kt-portlet--fit kt-portlet--head-noborder kt-portlet--height-fluid',
        headClass: 'kt-portlet__space-x text-danger',
        fluidHalfHeight: false,
        headNoBorder: true,
        bodyFit: true
      },false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }